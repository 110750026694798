"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { identity } from "fp-ts/lib/function";
import { UserAddressSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { AddressForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component";
import {
  buildColumnsForCustomFields,
  getTranslatedFieldValue
} from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const SCOPE = "user.address";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = (polyglot) => calculateSkeletonHeight(UserAddressSchema(polyglot));
export const AddressCard = ({ userId, onSubmit, missingFields }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [data, setData] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [formMode, setFormMode] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const getUserAddress = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    try {
      const userAddress = yield UserAddressAPI.findByUserId(userId);
      setData(userAddress);
      if ((userAddress == null ? void 0 : userAddress.entries) && ((_a2 = userAddress == null ? void 0 : userAddress.entries) == null ? void 0 : _a2.length) < 1) setShowDetails(false);
    } catch (error) {
      showMessage(polyglot.t("AddressCard.errorMessages.load"), "error");
    }
  }), [polyglot, showMessage, userId]);
  useEffect(() => {
    getUserAddress();
  }, [getUserAddress]);
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("AddressCard.addressLine1"),
        accessorFn: identity,
        id: "addressLine1",
        enableSorting: false,
        cell: ({
          row: {
            original: { addressLine1 }
          }
        }) => /* @__PURE__ */ jsx("div", { children: addressLine1 != null ? addressLine1 : "" })
      },
      {
        header: () => polyglot.t("AddressCard.addressLine2"),
        accessorFn: identity,
        id: "addressLine2",
        enableSorting: false,
        cell: ({
          row: {
            original: { addressLine2 }
          }
        }) => /* @__PURE__ */ jsx("div", { children: addressLine2 != null ? addressLine2 : "" })
      },
      {
        header: () => polyglot.t("AddressCard.city"),
        accessorFn: identity,
        id: "city",
        enableSorting: false,
        cell: ({
          row: {
            original: { city }
          }
        }) => /* @__PURE__ */ jsx("div", { children: city != null ? city : "" })
      },
      {
        header: () => polyglot.t("AddressCard.country"),
        accessorFn: identity,
        id: "country",
        enableSorting: false,
        cell: ({
          row: {
            original: { country }
          }
        }) => /* @__PURE__ */ jsx("div", { children: country != null ? country : "" })
      },
      {
        header: () => polyglot.t("AddressCard.postcode"),
        accessorFn: identity,
        id: "postcode",
        enableSorting: false,
        cell: ({
          row: {
            original: { postcode }
          }
        }) => /* @__PURE__ */ jsx("div", { children: postcode != null ? postcode : "" })
      },
      ...buildColumnsForCustomFields(data == null ? void 0 : data.entries, { size: 80 }),
      {
        header: () => polyglot.t("AddressCard.effectiveDate"),
        accessorFn: identity,
        id: "effectiveDate",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        accessorFn: identity,
        id: "action",
        header: () => "",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("AddressCard.edit"),
            onClick: () => {
              setIsOpen(true);
              setFormMode(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [polyglot, data == null ? void 0 : data.entries, scopesContext]
  );
  const handleSubmit = useCallback(() => {
    setFormMode(void 0);
    setIsOpen(false);
    onSubmit();
  }, [onSubmit]);
  return !data ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      height: skeletonHeight(polyglot),
      sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
    }
  ) : /* @__PURE__ */ jsx(
    CardStructure,
    {
      cardTitle: polyglot.t("AddressCard.title"),
      cardScope: [SCOPE],
      showDetails,
      setShowDetails,
      userId,
      addAction: () => {
        setIsOpen(true);
        setFormMode(void 0);
      },
      showMissingInfo: !(data == null ? void 0 : data.effectiveRecord),
      cardFieldCustomUpdates: (_a = data.effectiveRecord) == null ? void 0 : _a.customUpdates,
      cardFieldStubs: [
        "address.addressLine1",
        "address.addressLine2",
        "address.city",
        "address.postcode",
        "address.country"
      ],
      cardFieldDetails: (data == null ? void 0 : data.effectiveRecord) && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("AddressCard.addressLine1"),
            fieldValue: data.effectiveRecord.addressLine1,
            fieldStub: "address.addressLine1"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("AddressCard.addressLine2"),
            fieldValue: data.effectiveRecord.addressLine2,
            fieldStub: "address.addressLine2"
          }
        ),
        /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "City", fieldValue: data.effectiveRecord.city, fieldStub: "address.city" }),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("AddressCard.postcode"),
            fieldValue: data.effectiveRecord.postcode,
            fieldStub: "address.postcode"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("AddressCard.country"),
            fieldValue: data.effectiveRecord.country,
            fieldStub: "address.country"
          }
        ),
        sortCustomFields(data.effectiveRecord.customUpdates).map(
          (f) => !f.field.isHidden && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: f.field.fieldName,
              fieldValue: getTranslatedFieldValue(f.value, polyglot)
            },
            f.field.fieldId
          )
        )
      ] }),
      drawerDetails: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
        AddressForm,
        {
          userId,
          initialValues: formMode,
          onSubmit: handleSubmit,
          onClose: () => setIsOpen(false),
          handleRefresh: () => getUserAddress()
        }
      ) }) }),
      addButtonTitle: polyglot.t("AddressCard.newAddress"),
      tableRowData: [...data == null ? void 0 : data.entries],
      tableColumn: columnData,
      editAction: () => {
        setIsOpen(true);
        setFormMode(data == null ? void 0 : data.effectiveRecord);
      },
      missingInfoIcon: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user:all"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.Address)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
    }
  );
};
