"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { FormControl, Stack } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import {
  getCountryOptionsForSelectComponent,
  getEURegionCoutry,
  getUKRegionCountry
} from "@v2/infrastructure/country/country.util";
import { popularCurrencyOptions } from "@v2/infrastructure/currency/currency.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import {
  getAccountNumberValidation,
  getSortCodeValidation
} from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import {
  UserBankAccountAPI,
  UserBankAccountEndpoints
} from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { areAllValuesNull } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { spacing } from "@/v2/styles/spacing.styles";
const UserBankAccountFormSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  effectiveDate: defaultFieldsMap["bankAccount.effectiveDate"] && defaultFieldsMap["bankAccount.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).nullable().required(
    polyglot.t("UserBankInfoForm.schema.requiredField", {
      field: polyglot.t("UserBankInfoForm.effectiveDate")
    })
  ) : Yup.string().nullable().notRequired(),
  accountName: defaultFieldsMap["bankAccount.accountName"] && defaultFieldsMap["bankAccount.accountName"].isRequired ? Yup.string().nullable().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Account Name", maxLength: 30 })).required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.accountName") })
  ) : Yup.string().nullable().notRequired(),
  accountNumber: defaultFieldsMap["bankAccount.accountNumber"] && defaultFieldsMap["bankAccount.accountNumber"].isRequired ? getAccountNumberValidation(polyglot) : Yup.string().nullable().notRequired(),
  sortCode: defaultFieldsMap["bankAccount.sortCode"] && defaultFieldsMap["bankAccount.sortCode"].isRequired ? getSortCodeValidation(polyglot) : Yup.string().matches(/^[0-9a-zA-Z-]*$/, polyglot.t("UserBankInfoForm.schema.onlyLettersAndNumbers")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.onlyLettersAndNumbers")).when("currency", {
    is: (currency) => currency === "GBP",
    then: (schema) => schema.when("accountNumber", {
      is: (value) => /^[0-9]{8}$/.test(value),
      then: (schema2) => schema2.matches(/^[0-9]{6}$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode"))
    }).when("accountNumber", {
      is: (value) => /[a-zA-Z]+/.test(value),
      // if value contains letters
      then: (schema2) => schema2.matches(/^[a-zA-Z]{6}[a-zA-Z0-9]{2,5}$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode"))
    })
  }).nullable().notRequired(),
  bankName: defaultFieldsMap["bankAccount.bankName"] && defaultFieldsMap["bankAccount.bankName"].isRequired ? Yup.string().nullable().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Bank Name", maxLength: 30 })).required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.bankName") })
  ) : Yup.string().nullable().notRequired(),
  country: defaultFieldsMap["bankAccount.country"] && defaultFieldsMap["bankAccount.country"].isRequired ? Yup.string().nullable().required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.country") })
  ) : Yup.string().nullable().notRequired(),
  currency: defaultFieldsMap["bankAccount.currency"] && defaultFieldsMap["bankAccount.currency"].isRequired ? Yup.string().nullable().required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.currency") })
  ) : Yup.string().nullable().notRequired()
});
export const UserBankAccountSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
  const { polyglot } = usePolyglot();
  const { data: bankAccountInfo } = useApiClient(UserBankAccountEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.BankAccount);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.BankAccount);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2;
    const values = {};
    if (defaultFieldsMap["bankAccount.accountName"]) values.accountName = (_a2 = bankAccountInfo == null ? void 0 : bankAccountInfo.accountName) != null ? _a2 : null;
    if (defaultFieldsMap["bankAccount.accountNumber"]) values.accountNumber = (_b2 = bankAccountInfo == null ? void 0 : bankAccountInfo.accountNumber) != null ? _b2 : null;
    if (defaultFieldsMap["bankAccount.sortCode"]) values.sortCode = (_c2 = bankAccountInfo == null ? void 0 : bankAccountInfo.sortCode) != null ? _c2 : null;
    if (defaultFieldsMap["bankAccount.bankName"]) values.bankName = (_d2 = bankAccountInfo == null ? void 0 : bankAccountInfo.bankName) != null ? _d2 : null;
    if (defaultFieldsMap["bankAccount.country"]) values.country = (_e2 = bankAccountInfo == null ? void 0 : bankAccountInfo.country) != null ? _e2 : null;
    if (defaultFieldsMap["bankAccount.currency"]) values.currency = (_f2 = bankAccountInfo == null ? void 0 : bankAccountInfo.currency) != null ? _f2 : null;
    if (defaultFieldsMap["bankAccount.effectiveDate"]) values.effectiveDate = (_g2 = bankAccountInfo == null ? void 0 : bankAccountInfo.effectiveDate) != null ? _g2 : null;
    values.customUpdates = (_i2 = (_h2 = bankAccountInfo == null ? void 0 : bankAccountInfo.customUpdates) == null ? void 0 : _h2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _i2 : [];
    return values;
  }, [defaultFieldsMap, bankAccountInfo, customFieldIds]);
  const validationSchema = useMemo(() => UserBankAccountFormSchema(defaultFieldsMap, polyglot), [
    defaultFieldsMap,
    polyglot
  ]);
  const formik = useFormik({
    // all default values are required here = no need to clean up data
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2;
      setSaving(true);
      try {
        const allValuesNull = areAllValuesNull(values);
        if (allValuesNull) {
          onNext();
        } else {
          const valuesWithEffectiveDates = __spreadProps(__spreadValues({}, values), {
            effectiveDate: (_a2 = bankAccountInfo == null ? void 0 : bankAccountInfo.effectiveDate) != null ? _a2 : new LocalDate().toDateString()
          });
          if (bankAccountInfo && bankAccountInfo.bankAccountId) {
            yield UserBankAccountAPI.update(userId, bankAccountInfo.bankAccountId, valuesWithEffectiveDates);
          } else {
            yield UserBankAccountAPI.create(userId, valuesWithEffectiveDates);
          }
          onNext();
        }
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.bankAccountNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  const countryOptions = useMemo(() => getCountryOptionsForSelectComponent(), []);
  const selectedCountryOptions = useMemo(() => countryOptions.find(({ value }) => value === formik.values.country), [
    formik,
    countryOptions
  ]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.BankAccount, polyglot))) != null ? _a : polyglot.t("UserBankInfoForm.bankAccount") }),
    defaultFieldsMap["bankAccount.country"] && /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "country",
        label: (_b = translateCustomField(defaultFieldsMap["bankAccount.country"].fieldName, polyglot)) != null ? _b : polyglot.t("BankForm.country"),
        options: countryOptions || [],
        value: selectedCountryOptions || null,
        defaultValue: selectedCountryOptions,
        compareValue: (_c = formik.values.country) != null ? _c : " ",
        onChange: (_, x) => {
          var _a2, _b2, _c2;
          formik.setFieldValue("country", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          const EUCountries = (_b2 = getEURegionCoutry()) == null ? void 0 : _b2.options.map((o) => o.value);
          const isEUCountry = (x == null ? void 0 : x.value) && (EUCountries == null ? void 0 : EUCountries.includes(x.value));
          if (isEUCountry && !formik.values.currency) {
            formik.setFieldValue("currency", "EUR");
            return;
          }
          const UKCountries = (_c2 = getUKRegionCountry()) == null ? void 0 : _c2.options.map((o) => o.value);
          const isUKCountry = (x == null ? void 0 : x.value) && (UKCountries == null ? void 0 : UKCountries.includes(x.value));
          if (isUKCountry && !formik.values.currency) formik.setFieldValue("currency", "GBP");
        },
        error: !!formik.errors.country && Boolean(formik.touched.country),
        helperText: formik.touched.country ? formik.errors.country : "",
        required: (_d = defaultFieldsMap["bankAccount.country"]) == null ? void 0 : _d.isRequired
      }
    ) }),
    defaultFieldsMap["bankAccount.currency"] && /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "currency",
        label: (_e = translateCustomField(defaultFieldsMap["bankAccount.currency"].fieldName, polyglot)) != null ? _e : polyglot.t("BankForm.currency"),
        options: popularCurrencyOptions,
        value: (_f = popularCurrencyOptions.find(({ value }) => value === formik.values.currency)) != null ? _f : null,
        compareValue: (_g = formik.values.currency) != null ? _g : null,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("currency", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        error: !!formik.errors.currency && formik.touched.currency,
        helperText: formik.touched.currency ? formik.errors.currency : "",
        required: (_h = defaultFieldsMap["bankAccount.currency"]) == null ? void 0 : _h.isRequired
      }
    ),
    defaultFieldsMap["bankAccount.bankName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "bankName",
        label: (_i = translateCustomField(defaultFieldsMap["bankAccount.bankName"].fieldName, polyglot)) != null ? _i : polyglot.t("BankForm.bank"),
        value: formik.values.bankName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("bankName", ""),
        helperText: hasSubmitted && formik.errors.bankName,
        error: hasSubmitted && !!formik.errors.bankName,
        required: (_j = defaultFieldsMap["bankAccount.bankName"]) == null ? void 0 : _j.isRequired
      }
    ),
    defaultFieldsMap["bankAccount.accountName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountName",
        label: (_k = translateCustomField(defaultFieldsMap["bankAccount.accountName"].fieldName, polyglot)) != null ? _k : polyglot.t("BankForm.accountName"),
        value: formik.values.accountName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("accountName", ""),
        helperText: hasSubmitted && formik.errors.accountName,
        error: hasSubmitted && !!formik.errors.accountName,
        required: (_l = defaultFieldsMap["bankAccount.accountName"]) == null ? void 0 : _l.isRequired
      }
    ),
    defaultFieldsMap["bankAccount.accountNumber"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountNumber",
        label: (_m = translateCustomField(defaultFieldsMap["bankAccount.accountNumber"].fieldName, polyglot)) != null ? _m : polyglot.t("BankForm.accountNumber"),
        value: formik.values.accountNumber,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("accountNumber", ""),
        helperText: hasSubmitted && formik.errors.accountNumber,
        error: hasSubmitted && !!formik.errors.accountNumber,
        required: (_n = defaultFieldsMap["bankAccount.accountNumber"]) == null ? void 0 : _n.isRequired
      }
    ),
    defaultFieldsMap["bankAccount.sortCode"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "sortCode",
        label: (_o = translateCustomField(defaultFieldsMap["bankAccount.sortCode"].fieldName, polyglot)) != null ? _o : polyglot.t("OnboardingByUser.sortCodeBICSWIFT"),
        value: formik.values.sortCode,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("sortCode", ""),
        helperText: hasSubmitted && formik.errors.sortCode,
        error: hasSubmitted && !!formik.errors.sortCode,
        required: (_p = defaultFieldsMap["bankAccount.sortCode"]) == null ? void 0 : _p.isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_q = formik.values.customUpdates) != null ? _q : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    )
  ] }) }) });
};
